// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-field {
  position: relative;
}
.password-field i {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Settings-MyData/password.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AAER","sourcesContent":[".password-field {\n    position: relative;\n    i {\n        position: absolute;\n        bottom: 10px;\n        right: 10px;\n        color: #666;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
