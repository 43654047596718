// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form.control:focus {
  border-color: #76AEB7 !important;
  box-shadow: unset !important;
}

.inline-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.inline-dropdown label {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/Dropdown/dropdown.scss"],"names":[],"mappings":"AAIA;EACE,gCAAA;EACA,4BAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AAHF;AAIE;EACE,SAAA;AAFJ","sourcesContent":["$insertRowBg: rgb(255, 255, 204);\r\n$borderColor: #dee2e6;\r\n$primaryColor: #76AEB7;\r\n\r\n.form.control:focus {\r\n  border-color: #76AEB7 !important;\r\n  box-shadow: unset !important;\r\n}\r\n\r\n.inline-dropdown {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n  label {\r\n    margin: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
