// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}

.fixedPanel {
  margin-left: 15rem;
}

@media (max-width: 991px) {
  .fixedPanel {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .fixedPanel {
    margin-bottom: 7rem;
  }
}
.footer {
  background: #585858;
  border-top: 1px solid #e7dee9;
  padding: 10px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: 0.825rem;
  font-family: var(--font-family-monts);
  position: fixed;
  width: 100%;
  bottom: 0;
  height: auto !important;
  padding-left: 16rem;
  left: 0;
  z-index: 10;
}

.footer a {
  color: #1bcfb4;
  font-size: inherit;
}

@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
    padding-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/ViewWrapper/index.scss"],"names":[],"mappings":"AAAA;EAEE,+CAAA;EACA,WAAA;EACA,8BAAA;EAGA,aAAA;EAIA,sBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AACA;EACE;IACE,cAAA;EAEF;AACF;AACA;EACE;IACE,mBAAA;EACF;AACF;AAEA;EACE,mBAAA;EACA,6BAAA;EACA,kBAAA;EACA,0BAAA;EACA,+BAAA;EACA,kCAAA;EACA,8BAAA;EACA,mBAAA;EACA,qCAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,OAAA;EACA,WAAA;AAAF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF;;AACA;EACE;IACE,cAAA;IACA,WAAA;IACA,eAAA;EAEF;AACF","sourcesContent":[".main-panel {\n  -webkit-transition: width 0.25s ease, margin 0.25s ease;\n  transition: width 0.25s ease, margin 0.25s ease;\n  width: 100%;\n  min-height: calc(100vh - 70px);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -ms-flex-direction: column;\n  flex-direction: column;\n}\n\n.fixedPanel {\n  margin-left: 15rem;\n}\n@media (max-width: 991px) {\n  .fixedPanel {\n    margin-left: 0;\n  }\n}\n\n@media (max-width: 767px) {\n  .fixedPanel {\n    margin-bottom: 7rem;\n  }\n}\n\n.footer {\n  background: #585858;\n  border-top: 1px solid #e7dee9;\n  padding: 10px 1rem;\n  transition: all 0.25s ease;\n  -moz-transition: all 0.25s ease;\n  -webkit-transition: all 0.25s ease;\n  -ms-transition: all 0.25s ease;\n  font-size: calc(0.875rem - 0.05rem);\n  font-family: var(--font-family-monts);\n  position: fixed;\n  width: 100%;\n  bottom: 0;\n  height: auto !important ;\n  padding-left: 16rem;\n  left: 0;\n  z-index: 10;\n}\n.footer a {\n  color: #1bcfb4;\n  font-size: inherit;\n}\n@media (max-width: 991px) {\n  .footer {\n    margin-left: 0;\n    width: 100%;\n    padding-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
