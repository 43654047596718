// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-client-icon {
  border: 1px solid #000 !important;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 9px;
  cursor: pointer;
  font-size: 13px;
}

.new-client-modal-body .overlay_w {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  color: #76AEB7;
}
.new-client-modal-body .showWithOverlay {
  position: relative;
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Clients/clientModal.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;AACJ;;AAGI;EACI,oCAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;AAAR;AAEI;EACI,kBAAA;EACA,UAAA;AAAR","sourcesContent":[".add-client-icon {\n    border: 1px solid #000 !important;\n    border-radius: 100%;\n    height: 24px;\n    width: 24px;\n    line-height: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 9px;\n    cursor: pointer;\n    font-size: 13px;\n}\n\n.new-client-modal-body {\n    .overlay_w {\n        background: rgba(255, 255, 255, 0.7);\n        position: absolute;\n        top: 0;\n        right: 0;\n        left: 0;\n        bottom: 0;\n        z-index: 3;\n        cursor: pointer;\n        font-size: 20px;\n        text-align: center;\n        display: flex;\n        justify-content: center;\n        padding-top: 200px;\n        color: #76AEB7;\n    }\n    .showWithOverlay {\n        position: relative;\n        z-index: 3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
