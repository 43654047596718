// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showassetsbtn {
  font-size: 12px !important;
  margin-top: 14px;
  padding: 12px 10px;
}

.productAssetsfileinput {
  max-width: 100px;
  height: auto;
}

.productAssetsfileinput::before {
  max-width: 100px;
  font-weight: normal;
}

.product-form .overlay_w {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  color: #76AEB7;
}
.product-form .showWithOverlay {
  z-index: 4;
  position: relative;
}
.product-form .active-user-alert {
  z-index: 5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/ProductsNew/index.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAGE;EACE,oCAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;AAAJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ;AAGE;EACE,UAAA;EACA,2BAAA,EAAA,eAAA;EACA,yBAAA,EAAA,WAAA,EACA,mBAAA,EACA,4BAAA,EACA,2BAAA;EACA,iBAAA,EAAA,iFAAA;EACA,oBAAA;AADJ","sourcesContent":[".showassetsbtn {\n  font-size: 12px !important;\n  margin-top: 14px;\n  padding: 12px 10px;\n}\n\n.productAssetsfileinput {\n  max-width: 100px;\n  height: auto;\n}\n\n.productAssetsfileinput::before {\n  max-width: 100px;\n  font-weight: normal;\n}\n\n.product-form {\n  .overlay_w {\n    background: rgba(255, 255, 255, 0.7);\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n    z-index: 3;\n    cursor: pointer;\n    font-size: 20px;\n    text-align:center;\n    display: flex;\n    justify-content: center;\n    padding-top: 200px;\n    color: #76AEB7; \n  }\n  .showWithOverlay {\n    z-index: 4;\n    position: relative;\n  }\n\n  .active-user-alert {\n    z-index: 5;\n    -webkit-touch-callout: none; /* iOS Safari */\n    -webkit-user-select: none; /* Safari */\n    -khtml-user-select: none; /* Konqueror HTML */\n    -moz-user-select: none; /* Old versions of Firefox */\n    -ms-user-select: none; /* Internet Explorer/Edge */\n    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\n    pointer-events: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
