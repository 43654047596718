// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal6OPercentWidth {
  max-width: none !important;
  width: 60%;
}

.modal5OPercentWidth {
  max-width: none !important;
  width: 50%;
}

.modal45PercentWidth {
  max-width: none !important;
  width: 45%;
}

@media (max-width: 720px) {
  .modal42PercentWidth {
    max-width: none !important;
    width: 95%;
  }
}
@media (min-width: 720px) {
  .modal42PercentWidth {
    max-width: none !important;
    width: 42%;
  }
}
.modal40PercentWidth {
  max-width: none !important;
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/POPUP-COMPONENTS/modalWidths.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,UAAA;AACF;;AACA;EACE,0BAAA;EACA,UAAA;AAEF;;AAAA;EACE,0BAAA;EACA,UAAA;AAGF;;AADA;EACE;IACE,0BAAA;IACA,UAAA;EAIF;AACF;AAFA;EACE;IACE,0BAAA;IACA,UAAA;EAIF;AACF;AAFA;EACE,0BAAA;EACA,UAAA;AAIF","sourcesContent":[".modal6OPercentWidth {\r\n  max-width: none !important;\r\n  width: 60%;\r\n}\r\n.modal5OPercentWidth {\r\n  max-width: none !important;\r\n  width: 50%;\r\n}\r\n.modal45PercentWidth {\r\n  max-width: none !important;\r\n  width: 45%;\r\n}\r\n@media (max-width:720px) {\r\n  .modal42PercentWidth {\r\n    max-width: none !important;\r\n    width: 95%;\r\n  }\r\n}\r\n@media (min-width:720px) {\r\n  .modal42PercentWidth {\r\n    max-width: none !important;\r\n    width: 42%;\r\n  }\r\n}\r\n.modal40PercentWidth {\r\n  max-width: none !important;\r\n  width: 40%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
